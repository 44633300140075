import './campaignSettings.scss'
import React, { useState, useEffect, useContext } from 'react'
import useFetch from '../../../hooks/useFetch'
import Loading from '../../shared/Loading'
import { Button } from '@mui/material'
import HomePageSection from './sections/HomePageSection'
import FAQSection from './sections/FAQSection'
import CustomBidFieldSection from './sections/CustomBidderDetailSection'
import SocialLinkSection from './sections/SocialLinkSection'
import ConfirmationDialog from '../../shared/ConfirmationDialog'
import { useHistory } from "react-router-dom";
import useCampaignValidation from './useCampaignValidation'
import ICampaign from '../../../interfaces/campaign'
import { RouteComponentProps } from 'react-router'
import { API_PATHS, CAMPAIGN_API_PATHS, getPaginatedCampaignsQueryString, USER_API_PATHS } from '../../../common/ApiPaths'
import useCommonFunctions from '../../../hooks/useCommonFunctions'
import { CampaignContext, CampaignDispatchContext } from '../../../context/CampaignContext'
import { OrganisationContext } from '../../../context/OrganisationContext'
import StaffSection from '../organisationSettings/StaffSection'
import { IUser } from '../../../interfaces/user'
import { UserRole } from '../../../common/enums/UserRole'
import { UserContext } from '../../../context/UserContext'
import CoreSettingsSection from './sections/CoreSettingsSection'
import BrandingSection from './sections/BrandingSection'
import FooterContentSection from './sections/FooterContentSection'
import NotificationsSection from './sections/NotificationsSection'
import { useErrorBoundary } from 'react-error-boundary'
import LocalisationSettingsSection from './sections/LocalisationSettingsSection'
import TermsAndConditionsSection from './sections/TermsAndConditionsSection'
import TotaliserSettingsSection from './sections/TotaliserSection'


interface InputProps {

}

const CampaignSettings: React.FunctionComponent<InputProps & RouteComponentProps<any>> = (props) => {
    const campaign = useContext(CampaignContext);
    const setCampaign = useContext(CampaignDispatchContext);
    const organisation = useContext(OrganisationContext)
    const { dBUser, auth0User } = useContext(UserContext)

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    const [users, setUsers] = useState<IUser[]>([])
    const [otherCampaignList, setOtherCampaignList] = useState<ICampaign[]>([])
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
    const [fetchedOwners, setFetchedOwners] = useState<boolean>(false)
    const [dialogProperties, setDialogProperties] = useState<any>({ type: "", title: "", message: "", errorDetails: "" })
    const [termsAndConditionsHtml, setTermsAndConditionsHtml] = useState<string>(campaign.termsAndConditionsHtml)


    const campaignsApi = useFetch(API_PATHS.CAMPAIGNS)
    const usersApi = useFetch(API_PATHS.USERS)
    const commonFunctions = useCommonFunctions()
    let history = useHistory();
    const { showBoundary } = useErrorBoundary();

    const campaignValidation = useCampaignValidation(campaign, otherCampaignList)

    // const { user } = useAuth0()

    useEffect(() => {
        if (!fetchedOwners && !loading && !(campaign && campaign.slug) && (props.match.params.campaignSlug !== "new")) {
            showBoundary('Error while fetching campaign')
            setLoading(false)
        }
        else {
            if (!fetchedOwners) {
                getOwners()
            }
            setTermsAndConditionsHtml(campaign.termsAndConditionsHtml)
            setError('')
            setLoading(false)
        }
    }, [campaign])

    const determineOwnerList = async (): Promise<string[]> => {

        // TODO check these with userIds instead of auth0Ids
        //always include bill, kam as user
        let ownerList: string[] = ['62ea2fabd5b8d5112906aeae', '63033a4d8d4df21e102ccfbd']// ['google-oauth2|107201638953183223161', 'auth0|62b07b21b690199c1c8a3b6a', 'auth0|62d6be64551c9652d7bd370c']

        const userId = await commonFunctions.getUserIdFromAuth0Id(encodeURIComponent(String(auth0User!.sub)))
        if (auth0User && !ownerList.find(element => element === userId)) {
            ownerList.push(userId)
        }

        return ownerList
    }

    const getCampaigns = async (): Promise<void> => {
        setLoading(true)

        try {
            // const userId = await commonFunctions.getUserIdFromAuth0Id(encodeURIComponent(String(user!.sub)))
            const result = await campaignsApi.get(`${CAMPAIGN_API_PATHS.GET_PAGINATED_CAMPAIGNS}${getPaginatedCampaignsQueryString(dBUser._id, dBUser.role, 1000, 0)}`)
            let filteredList = result.campaigns
            if (props.match.params.campaignSlug !== "new") {
                filteredList = result.campaigns.filter(element => element.slug !== props.match.params.campaignSlug)
            }

            setOtherCampaignList(filteredList)
            setLoading(false)
        }
        catch (err: any) {
            showBoundary(err)
            setLoading(false)
        }
    }

    const getOwners = async (): Promise<void> => {
        if (campaign.ownerList && campaign.ownerList.length) {
            setLoading(true)
            try {
                const result = await usersApi.get(`${USER_API_PATHS.GET_USERS_BY_USER_IDS.replace('{userIds}', campaign.ownerList.join(','))}`)
                setUsers(result.filter(x => x.role !== UserRole.NONE && x.role !== UserRole.SUPER_ADMIN))
                setLoading(false)
                setFetchedOwners(true)
            }
            catch (err: any) {
                showBoundary(err)
                setLoading(false)
            }
        }
    }

    const createCampaign = async (): Promise<void> => {
        if (campaignValidation.validateInputs()) {

            try {
                const ownerList: string[] = await determineOwnerList()
                const data: ICampaign = await campaignsApi.post(`${CAMPAIGN_API_PATHS.CREATE_CAMPAIGN}`, { ...campaign, ownerList: ownerList, organisationId: organisation._id, termsAndConditionsHtml: termsAndConditionsHtml }, dBUser._id)
                setDialogProperties({ type: "SUCCESS", title: "Campaign Created.", message: "Your campaign has successfully been created." })
                setIsConfirmationDialogOpen(true)
                history.push(`/organisations/${organisation.slug}/campaigns/${data.slug}`)
            }
            catch (err: any) {
                setDialogProperties({ type: "ERROR", title: "Create failed.", message: `Your campaign creation failed due to some error`, errorDetails: err.message })
                setIsConfirmationDialogOpen(true)
            }
        }
    }

    const saveCampaign = async (): Promise<void> => {
        if (campaignValidation.validateInputs()) {

            try {
                const data: ICampaign = await campaignsApi.put(
                    `${CAMPAIGN_API_PATHS.UPDATE_CAMPAIGN.replace('{campaignId}', campaign._id)}`,
                    { ...campaign, termsAndConditionsHtml: termsAndConditionsHtml},
                    dBUser._id
                )
                setDialogProperties({ type: "SUCCESS", title: "Save complete.", message: "Your campaign has successfully been saved." })
                setIsConfirmationDialogOpen(true)
                setCampaign(data)
                history.push(`/organisations/${organisation.slug}/campaigns/${campaign.slug}`)
            }
            catch (err: any) {
                setDialogProperties({ type: "ERROR", title: "Save failed.", message: `Your campaign did not save due to some error`, errorDetails: err.message })
                setIsConfirmationDialogOpen(true)
            }
        }
    }

    const editCampaignOwnerList = (userId: string, added: boolean) => {
        if (added) {
            setCampaign({ ...campaign, ownerList: [...campaign.ownerList, userId] })
        }
        else {
            let updated = campaign.ownerList.filter(x => x !== userId)
            setCampaign({ ...campaign, ownerList: updated })
        }
    }

    const closeDialog = () => {
        setIsConfirmationDialogOpen(false)
    }

    useEffect(() => {
        // if (user &&user.sub){
        if (props.match.params.campaignSlug === "new") {
            //initialise the lists so that the empty sections display.
            setCampaign({
                ...campaign,
                faqList: [],
                socialLinkList: [],
                customBidDetailList: [],
                isActive: true,
                cardFee: 2.5
            })
        }
        // else {
        //     getAuction()
        // }

        getCampaigns()
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [props.match.params.campaignSlug]);

    if (loading) {
        return (
            <Loading />
        )
    }

    // if (error) {
    //     return (
    //         <p>{error}</p>
    //     )
    // }

    return (
        <div className="campaign-settings-page">

            {campaign &&
                <CoreSettingsSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                    validationObject={campaignValidation.validationObject}
                />
            }
            {campaign && campaign.isShowTotaliser &&
                <TotaliserSettingsSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                    validationObject={campaignValidation.validationObject}
                />
            }

            {campaign &&
                <BrandingSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
            }

            {campaign &&
                <HomePageSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
            }

            {campaign &&
                <FooterContentSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
            }

            {campaign &&
                <NotificationsSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                    validationObject={campaignValidation.validationObject}
                />
            }

            {campaign &&
                <LocalisationSettingsSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
            }

            {campaign.socialLinkList &&
                <SocialLinkSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
            }

            {campaign.customBidDetailList &&
                <CustomBidFieldSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
            }
            {campaign.faqList &&
                <FAQSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                />
            }
            {campaign && dBUser.role !== UserRole.OPERATOR && (
                <StaffSection
                    userList={users ?? []}
                    getUserList={getOwners}
                    isCampaignView={true}
                    editCampaignOwnerList={editCampaignOwnerList}
                />
            )}
            {campaign &&
                <TermsAndConditionsSection
                    campaign={campaign}
                    setCampaign={setCampaign}
                    termsAndConditionsHtml={termsAndConditionsHtml}
                    setTermsAndConditionsHtml={setTermsAndConditionsHtml}
                />
            }

            <div className="fixed-action-section">
                <div></div>
                <div className="save-button-wrapper">
                    {!campaignValidation.isValidationPassed && <span className="validation-text">Errors highlighted in form - please resolve before saving.</span>}
                    {props.match.params.campaignSlug !== "new" && <Button className="gg-button" onClick={saveCampaign}>Save Campaign</Button>}
                    {props.match.params.campaignSlug === "new" && <Button className="gg-button" onClick={createCampaign}>Create Campaign</Button>}
                </div>
            </div>


            <ConfirmationDialog
                handleClose={closeDialog}
                isDialogOpen={isConfirmationDialogOpen}
                message={dialogProperties.message}
                errorDetails={dialogProperties.errorDetails}
                title={dialogProperties.title}
                type={dialogProperties.type}
                label={dialogProperties.label}
                primaryButton={<Button onClick={() => closeDialog()} className="gg-button" style={commonFunctions.determineButtonColor(dialogProperties.type)}>
                    Ok
                </Button>}
            />
        </div>
    )
}

export default CampaignSettings